import axios from "axios";
import {API_URL} from "@/config/constants.config";
export default() => {
    axios.defaults.baseURL = API_URL
    axios.interceptors.request.use((config) => {
        let accessToken = localStorage.getItem('BOUVET_TOKEN') ?? null
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    })

    axios.interceptors.request.use((response)=>{
        return Promise.resolve(response);
    },(error) => {
        return Promise.reject(error);
    })
}