<template>
  <div>
    <button v-if="shouldShowPrintButton" @click="handlePrint" class="btn btn-sm custom-btn-tertia mr-1">
      <font-awesome-icon icon="print" />
      {{ printLabel }}
    </button>

    <button v-if="shouldShowSaveButton" @click="handleSave" :disabled="saveDisabled" class="btn btn-sm btn-success mr-1">
      <font-awesome-icon icon="floppy-disk" />
      {{ saveLabel }}
    </button>

    <button v-if="shouldShowEditButton" @click="handleEdit" :disabled="editDisabled" class="btn btn-sm btn btn-primary mr-1">
      <font-awesome-icon icon="edit" />
      {{ editLabel }}
    </button>

    <button v-if="shouldShowDeleteButton" @click="handleDelete" :disabled="deleteDisabled" class="btn btn-sm btn btn-danger mr-1">
      <font-awesome-icon icon="trash" />
      {{ deleteLabel }}
    </button>

    <button v-if="shouldShowCancelButton" @click="handleCancel" class="btn btn-sm btn btn-warning mr-1">
      <font-awesome-icon icon="ban" />
      {{ cancelLabel }}
    </button>

    <button v-if="showClose" @click="handleClose" :disabled="closeDisabled" :data-dismiss="closeDismiss ? 'modal' : null" class="btn btn-sm btn btn-secondary mr-1">
      <font-awesome-icon icon="times" />
      {{ closeLabel }}
    </button>

  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue';

const props = defineProps({
  entryStatus: {
    type: String,
    default: 'add',
  },
  showSave: {
    type: Boolean,
    default: true,
  },
  showClose: {
    type: Boolean,
    default: true,
  },
  showDelete: {
    type: Boolean,
    default: false,
  },
  showEdit: {
    type: Boolean,
    default: true,
  },
  showCancel: {
    type: Boolean,
    default: false,
  },
  showPrint: {
    type: Boolean,
    default: false,
  },
  saveLabel: {
    type: String,
    default: 'Save',
  },
  closeLabel: {
    type: String,
    default: 'Close',
  },
  deleteLabel: {
    type: String,
    default: 'Delete',
  },
  editLabel: {
    type: String,
    default: 'Edit',
  },
  cancelLabel: {
    type: String,
    default: 'Cancel',
  },
  printLabel: {
    type: String,
    default: 'Print',
  },
  saveDisabled: {
    type: Boolean,
    default: false,
  },
  closeDisabled: {
    type: Boolean,
    default: false,
  },
  deleteDisabled: {
    type: Boolean,
    default: false,
  },
  editDisabled: {
    type: Boolean,
    default: false,
  },
  printDisabled: {
    type: Boolean,
    default: false,
  },
  closeDismiss: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['save', 'edit', 'cancel', 'close', 'delete', 'dismissModal']);
const isEditEnabled = ref(false);

const shouldShowSaveButton = computed(() => {if (props.showSave) {return props.showEdit ? (isEditEnabled.value || props.entryStatus === 'add') : true;}return false;});
const shouldShowEditButton = computed(() => props.showEdit && !isEditEnabled.value && props.entryStatus === 'update');
const shouldShowDeleteButton = computed(() => props.showDelete && props.entryStatus === 'update');
const shouldShowCancelButton = computed(() => isEditEnabled.value && props.entryStatus === 'update');
const shouldShowPrintButton = computed(() => props.showPrint && !isEditEnabled.value && props.entryStatus === 'update');


const handleSave = () => {
  if (!props.saveDisabled) {
    emit('save');
    isEditEnabled.value = false;
  }
};

const handleClose = () => {
  if (!props.closeDisabled) {
    emit('close');
    isEditEnabled.value = false;
    if (props.closeDismiss) {
      emit('dismissModal');
    }
  }
};

const handleDelete = () => {
  if (props.entryStatus === 'update' && !props.deleteDisabled) {
    emit('delete');
  }
};

const handleEdit = () => {
  isEditEnabled.value = true;
  emit('edit', { showSave: true, showEdit: false, showCancel: true });
};

const handlePrint = () => {
  if (!props.printDisabled) {
    emit('print');
  }
};

const handleCancel = () => {
  emit('cancel');
  isEditEnabled.value = false;
};
</script>

<style scoped>
/* Add styles as needed */
</style>

<!--Default button-->
<!--<crud-button-->
<!--    :entryStatus='entryStatus'-->
<!--    @save="saveDocument()"-->
<!--    @edit="handleEdit"-->
<!--    @cancel="methods.resetFields()"-->
<!--    @close="methods.resetFields()"-->
<!--    @delete="handleDelete"-->
<!--    :closeDismiss="true"-->
<!--/>-->

<!--if you want to hide delete and edit-->
<!--<crud-button-->
<!--    :entryStatus='entryStatus'-->
<!--    :showEdit="false"-->
<!--    :showDelete="false"-->
<!--    @save="saveDocument()"-->
<!--    @edit="handleEdit"-->
<!--    @cancel="methods.resetFields()"-->
<!--    @close="methods.resetFields()"-->
<!--    @delete="handleDelete"-->
<!--    :closeDismiss="true"-->
<!--/>-->