<template>
  <div>
    <button
        id="back-to-top"
        href="#"
        class="btn btn-lg pmc-bg-prime"
        role="button"
        title="Go back on top"
        v-if="isVisible"
        @click="scrollToTop"
    >
      <font-awesome-icon icon="chevron-circle-up" />
    </button>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: 'ButtonGotoUp',
  components: { FontAwesomeIcon },

  setup() {
    const isVisible = ref(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    onMounted(() => {
      window.addEventListener('scroll', () => {
        isVisible.value = window.scrollY > 50;
      });
    });

    return {
      isVisible,
      scrollToTop,
    };
  },
});
</script>

<style scoped>
#back-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  height: auto !important;
  width: auto !important;
  display: none;
  color: white;
}
</style>
