<template>
  <div class="card-component">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CardComponent',
};
</script>

<style scoped>
/* Add your card styling here */
.card {
  //border: 1px solid #ccc;
  border-radius: 0;
  //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  //padding: 1rem;
  //background-color: #fff;
}
</style>
