
<script>
export default {
  name: "BaseSelect",
  inheritAttrs: false,
  customOptions: {}
}
</script>
<script setup>
</script>

<template>
  <multi-select
      class="custom-multiselect"
></multi-select>
</template>

<style scoped>
 .custom-multiselect {
   --ms-font-size: .8em;
   --ms-line-height: 1;
   --ms-option-font-size: .5em;
   --ms-max-height: 10rem;
 }
</style>