
<script>
export default {
  name: 'ProgressBar'
}
</script>
<script setup>
import {defineProps} from "vue";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },

  loadingMessage: {
    type : String,
    default : "Loading"
  },
})
</script>

<template>
  <div v-if="props.loading" class="overlay">
    <span class="spinner"  aria-hidden="true"></span>
    <strong>{{props.loadingMessage}}</strong>
  </div>
</template>
<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
