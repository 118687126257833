<template>
  <button type="button"
          @click="thisButtonEvent"
          :class="buttonClass">
    <slot name="button-icon"/>
    {{buttonLabel}}
  </button>
</template>

<script>
export default {
  name: 'ButtonComponent',

  props: {
    id: {
      type: String,
      default: 'ex-btn',
    },

    name: {
      type: String,
      default: 'ExButton',
    },

    buttonLabel: {
      type: String,
      default: 'my-btn-modal',
    },

    buttonClass: {
      type: String,
      default: 'btn btn-sm',
    },
  },

  /** data **/
  data () {
    return {}
  },

  /** methods**/
  methods: {
    thisButtonEvent (param) {
      this.$emit('buttonEvent',param);
    },
  },

  /**  computed  **/
  computed: {},

  /** mounted **/
  mounted () {
  },

  /** created **/
  created () {
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  unmounted () {
  },

}
</script>

<style scoped>

</style>
