<template>
  <small class="mr-1 mt-2" v-if="pageObject.total > 0">
    {{ `${pageObject.from} - ${pageObject.to} of ${pageObject.total}` }}
  </small>
  <small class="mr-1 mt-2" v-else>
    No results found
  </small>
  <div class="text-left small">
    <nav aria-label="..." class="pt-0">
      <ul class="pagination pagination-sm">
        <li class="page-item" :class="{ 'disabled': pageObject.current_page === 1 }">
          <button class="page-link x-small" tabindex="-1" @click="stepBackward">
            <font-awesome-icon icon="angles-left"/>
          </button>
        </li>
        <li class="page-item" :class="{ 'disabled': pageObject.current_page === 1 }">
          <button class="page-link x-small" tabindex="-1" @click="previousPage">
            <font-awesome-icon icon="angle-left"/>
          </button>
        </li>
        <li v-for="(page, index) in paginationPages" :key="index" class="page-item">
          <button v-if="page !== '...' || (inputActive && page !== '...')" class="page-link x-small" @click="setPage(page)" :class="{ 'active': page === pageObject.current_page }">{{ page }}</button>
          <button v-else-if="!inputActive" class="page-link x-small" @click="showPageInput">
            <font-awesome-icon icon="ellipsis"/>
          </button>
          <input v-if="inputActive && page === '...'"
                 class="page-link x-small input-number"
                 type="number" v-model="pageNumberInput"
                 @keyup.enter="goToPage"
                 @click.stop=""
                 @mouseleave="showPageInput">
        </li>
        <li class="page-item" :class="{ 'disabled': pageObject.current_page === pageObject.last_page }">
          <button class="page-link x-small" @click="nextPage">
            <font-awesome-icon icon="angle-right"/>
          </button>
        </li>
        <li class="page-item" :class="{ 'disabled': pageObject.current_page === pageObject.last_page }">
          <button class="page-link x-small" @click="stepForward">
            <font-awesome-icon icon="angles-right"/>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "PaginationPage",
  components: { FontAwesomeIcon },

  data() {
    return {
      pageNumberInput: "",
      inputActive: false,
    };
  },

  props: {
    pageObject: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ["set-page"],

  methods: {
    setPage(page) {
      this.inputActive = false;
      this.$emit("set-page", page);
    },
    goToPage() {
      const pageNumber = parseInt(this.pageNumberInput);
      if (pageNumber >= 1 && pageNumber <= this.pageObject.last_page) {
        this.$emit("set-page", pageNumber);
        this.pageNumberInput = "";
        this.inputActive = false;
      }
    },
    showPageInput() {
      this.inputActive = !this.inputActive;
    },
    previousPage() {
      const previousPage = this.pageObject.current_page - 1;
      if (previousPage >= 1) {
        this.$emit("set-page", previousPage);
      }
    },
    nextPage() {
      const nextPage = this.pageObject.current_page + 1;
      if (nextPage <= this.pageObject.last_page) {
        this.$emit("set-page", nextPage);
      }
    },
    stepBackward() {
      this.$emit("set-page", 1);
    },
    stepForward() {
      this.$emit("set-page", this.pageObject.last_page);
    },
  },

  computed: {
    paginationPages() {
      const pages = [];
      const currentPage = this.pageObject.current_page;
      const lastPage = this.pageObject.last_page;

      if (lastPage > 1) {
        const maxVisibleButtons = 5;
        const halfVisibleButtons = Math.floor(maxVisibleButtons / 2);

        if (currentPage <= halfVisibleButtons + 1) {
          for (let i = 1; i <= Math.min(lastPage, maxVisibleButtons); i++) {
            pages.push(i);
          }
          if (lastPage > maxVisibleButtons) {
            pages.push("...");
          }
        } else if (currentPage >= lastPage - halfVisibleButtons) {
          for (let i = Math.max(1, lastPage - maxVisibleButtons + 1); i <= lastPage; i++) {
            pages.push(i);
          }
        } else {
          for (let i = currentPage - halfVisibleButtons; i <= currentPage + halfVisibleButtons; i++) {
            pages.push(i);
          }
          pages.push("...");
        }
      }
      return pages;
    },
  },
};
</script>

<style scoped>
.input-number {
  width: 50px;
}
</style>
