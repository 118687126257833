<template>
  <div :id="id" class="sidenav text-left bg-info-light">
    <slot name="contents"></slot>
  </div>
</template>

<script>
export default {
  name: 'SideNav',

  props:{
    id : {
      type : String,
      default : 'mySidenav',
    }
  },

  data () {
    return {}
  },

  methods: {},

  computed: {},

  onMounted () {
  },


  onUnmounted () {
  },

}
</script>

<style>


.sidenav {
  height: 100%;
  width: 155px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 16px;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 20px;

}

.sidenav a {
  padding: 2px 2px 2px 20px;
  text-decoration: none;
  font-size: 14px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover,
.sidenav a.active
{
  background-color: var(--tertia) !important;
  color: var(--light-font) !important;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 16px;
  margin-left: 50px;
}

#main {
  transition: margin-left .5s;
  margin-left: 155px;
  /*padding: 16px;*/
}

</style>
